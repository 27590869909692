import { getData, postData } from './_http'

const prefix = process.env.VUE_APP_AAA_API

export function getToken (json) {
  return postData('/api/outer/auth/loginByAAA', json)
}
// 获取用户商业化角色 OR SDK角色
export function getPersonRole (json) {
  return getData('/api/outer/user/showData', json)
}
export function getAAAToken (json) {
  return postData(prefix + '/login/youdu/code', json)
}

// 获取用户是否有权限查看客户列表
export function getUserAuth (json) {
  return getData('/api/outer/user/rules', json);
}