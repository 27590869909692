import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from './utils/vant'
import './style/index.less'
// import './style/_variable.less'
import 'vant/lib/index.less'
import '@/style/vant.less'

import '@/permission.js'

import store from '@/store'
import Cookies from 'js-cookie'

Vue.config.productionTip = false
Vue.use(Vant)


/* 
 * @desc 获取url中的token参数
 * @return String url的token值
 */
function GetQueryString(){
  const url = window.location.search;
  const queryString = url.split('?')[1];
  const paramsArr = queryString.split('&');
  const obj = {};
  for (let i=0; i<paramsArr.length; i++) {
    let arr = paramsArr[i].split('=')
    obj[arr[0]] = arr[1]
  }
  return obj
}

//本地或测试环境,启动项目添加token
if (process.env.NODE_ENV != 'production') {
  const paramsObj = GetQueryString();
  store.commit('SET_PERSON_ROLE_TYPE', paramsObj['type'] ? paramsObj.type : 'BUSINESS')
  Cookies.set('token', paramsObj.token);
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
