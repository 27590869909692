<template>
  <div class="login">
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  mounted() {
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
.list-item{
  display: flex;
  justify-content: space-around;
  align-content: center;
}
</style>
