import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/login.vue'

Vue.use(VueRouter)

const routes = [
{
  path: '/',
  redirect: '/customer'
},
{
  path: '/customer',
  name: 'Customer',
  component: Home
},
{
  path: '/newCustomer',
  name: 'NewCustomer',
  component: () => import(/* webpackChunkName: "about" */ '../views/NewCustomer.vue')
},
{
  path: '/agent',
  name: 'Agent',
  component: () => import(/* webpackChunkName: "about" */ '../views/Agent.vue')
},
{
  path: '/customerDetail/:id',
  name: 'customerDetail',
  component: () => import(/* webpackChunkName: "about" */ '../views/customerDetail.vue')
},
// error-page
{
  path: '/403',
  name: 'NoAuth',
  component: () => import(/* webpackChunkName: "about" */ '@/views/error-page/403.vue')
},
{
  path: '/404',
  name: 'NotFound',
  component: () => import(/* webpackChunkName: "about" */ '@/views/error-page/404.vue')
},
{
  path: '*',
  redirect: '/404'
}
]

const router = new VueRouter({
  scrollBehavior: () => ({ y: 0 }),
  // base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
return originalPush.call(this, location).catch(err => err)
}

export default router
