<template>
  <div class="home">
    <van-nav-bar title="客户管理" @click-right="addNewCustomer">
      <template #right>
        <van-icon name="friends-o" size="24" />
      </template>
    </van-nav-bar>
    <van-pull-refresh v-model="loading.refresh" @refresh="onRefresh">
      <van-list
        v-model="loading.load"
        offset="10"
        :finished="loadFinshed[active]"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <van-tabs v-model="active" @change="handleTabChange" :class="{'tab-hide': personRole === 'SDK'}">
          <van-tab title="未成交客户" name="unsettled">
            <div class="unsettled w">
              <div class="search-input">
                <van-search
                  v-model="searchName"
                  placeholder="请输入客户名称/负责人"
                  @search="onSearchCustom"
                  @clear="onSearchCustom"
                />
              </div>
              <div class="empty" v-if="unsettledCustomerList.length === 0">
                <img src="@/assets/empty_1.svg" alt="空">
                <p>暂无更多数据</p>
              </div>
              <div v-else>
              <div class="custom-item" v-for="customer in unsettledCustomerList" :key="'unsettled' + customer.id" @click="gotoDetailCustomer(customer)">
                  <div class="customer-info">
                    <h3>{{customer.name}}</h3>
                    <p class="desc">{{customer.phone}}</p>
                    <p class='tags'>
                      <template v-if="customer.level">
                        <van-tag :color="levelColor[customer.level].background" :text-color="levelColor[customer.level].color" size="large">{{(customer.level || '')|formattter(enums.level)}}</van-tag>
                      </template>
                      <van-tag color="rgba(6,146,242,0.10)" text-color="#0692F2" size="large">{{(customer.stage || '')|formattter(enums.stage)}}</van-tag>
                    </p>
                  </div>
                  <div class="track-btn">
                    <p class="track-btn-edit" @click.stop="gotoEdit(customer)">
                      <img src="@/assets/icon_edit.svg" alt="">
                      <span>编辑</span>
                    </p>
                    <van-button class="pri-btn" type="primary" size="small" round @click.stop="gotoFollow(customer)">跟进</van-button>
                    <!-- <van-button class="pri-btn" type="primary" size="small" round @click.stop="gotoEdit(customer)">编辑</van-button> -->
                  </div>
                </div>
              </div>
            </div>
          </van-tab>
          <van-tab title="已成交客户" name="settled">
            <div class="closed w">
              <div class="search-input">
                <van-search
                  v-model="searchName"
                  placeholder="请输入客户名称/负责人"
                  @search="onSearchCustom"
                  @clear="onSearchCustom"
                />
              </div>
              <div class="empty" v-if="settledCustomerList.length === 0">
                <img src="@/assets/empty_1.svg" alt="空">
                <p>暂无更多数据</p>
              </div>
              <div v-else>
                <div class="custom-item" v-for="customer in settledCustomerList" :key="'settled' + customer.id" @click="gotoDetailCustomer(customer)">
                  <div class="customer-info">
                    <h3>{{customer.name}}</h3>
                    <p class="desc">{{customer.phone}}</p>
                    <p class='tags'>
                      <van-tag color="#FFEFE7" text-color="#FF6011" size="large">{{(customer.level || '')|formattter(enums.level)}}</van-tag>
                      <van-tag color="rgba(6,146,242,0.10)" text-color="#0692F2" size="large">{{(customer.stage || '')|formattter(enums.stage)}}</van-tag>
                    </p>
                  </div>
                  <div class="track-btn">
                    <p class="track-btn-edit" @click.stop="gotoEdit(customer)">
                      <img src="@/assets/icon_edit.svg" alt="">
                      <span>编辑</span>
                    </p>
                    <van-button class="pri-btn" type="primary" size="small" round @click.stop="gotoFollow(customer)">跟进</van-button>
                    <!-- <van-button class="pri-btn" type="primary" size="small" round @click.stop="gotoEdit(customer)">编辑</van-button> -->
                  </div>
                </div>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </van-list>
    </van-pull-refresh>
    <nav-bar :active="0" />
  </div>
</template>

<script>
import LinkList from '@/components/linkList'
import NavBar from '@/components/navBar.vue'
import { listCustomer } from '@/api/custom'
import { getUserAuth } from '@/api/auth'
import {
  LEVEL_MAP,
  STAGE_MAP
} from '@/constant/index'
import { mapState } from 'vuex'

const LEVEL_COLOR = {
  'A': {
    color: '#FE3B30',
    background: '#FFF3F3'
  },
  'B':{
    color: '#FF6011',
    background: '#FFEFE7'
  },
  'C': {
    color: '#029488',
    background: '#F2F9F9'
  },
}

export default {
  name: 'Home',
  components: {
    LinkList,
    NavBar
  },
  computed: {
    ...mapState({
      personRole: state => state.personRoleType
    }),
    enums () {
      return {
        level: LEVEL_MAP,
        stage: STAGE_MAP?.[this.personRole] || []
      }
    }
  },
  data () {
    return {
      searchName: '',
      loading: {
        refresh: false,
        load: false
      },
      active: 'unsettled',
      settledCustomerList: [],
      unsettledCustomerList: [],
      loadFinshed: {
        unsettled: false,
        settled: false
      },
      pagination: {
        settled: {
          pageNo: 0,
          pageSize: 15,
          total: 0
        },
        unsettled: {
          pageNo: 0,
          pageSize: 15,
          total: 0
        }
      },
      searchVal: {
        unsettled: '',
        settled: ''
      },
      levelColor: LEVEL_COLOR
    }
  },
  filters: {
    formattter (val, list) {
      return list.find(v => v.value == val)?.label
    }
  },
  methods: {
    async listCustomer () {
      const stage = this.active === 'unsettled'
        ? this.personRole === 'SDK' ? ['1001', '1002', '1003', '1004', '1005', '1006', '1007', '1008', '1009'] : ['1', '2', '3', '4', '5', '6'] : ['7', '8', '9', '10']
      
      
      // const stage = this.active === 'unsettled' ? ['1', '2', '3', '4', '5', '6'] : ['7', '8', '9', '10']

      
      const { pageNo, pageSize } = this.pagination[this.active]
      const { data } = await listCustomer({
        stage,
        business_id: 0,
        pageNo,
        pageSize,
        keyword: this.searchName
      })
      const { list, total } = data;
      // console.log(111, this[`${this.active}CustomerList`]);
      this[`${this.active}CustomerList`].push(...list)
      this.pagination[this.active].total = total
      this.loadFinshed[this.active] = this[`${this.active}CustomerList`].length >= total
      // console.log(this[`${this.active}CustomerList`].length >= total)
      this.$nextTick(() => {
        this.loading.load = false
      })
    },
    async onRefresh (resetKeyword) {
      this.searchVal[this.active] = ''
      this.pagination[this.active] = {
        pageNo: 1,
        pageSize: this.pagination[this.active].pageSize,
        total: 0
      }
      this[`${this.active}CustomerList`].length = 0
      await this.listCustomer()
      this.loading.refresh = false
    },
    // 切换客户列表，空值刷新
    handleTabChange () {
      // this[`${this.active}CustomerList`] = []
      if (!this[`${this.active}CustomerList`].length) {
        this.listCustomer()
      }
        
      this.searchName = '' // 清空查询
    },
    // 搜索
    onSearchCustom(val) {
      this[`${this.active}CustomerList`] = [];
      this.pagination[this.active] = {
        pageNo: 0,
        pageSize: 15,
        total: 0
      }
      this.listCustomer();
    },
    gotoFollow (customer) {
      this.$router.push({ path: `/customerDetail/${customer.id}`, query: { activeName: 'record' } })
    },
    gotoEdit (customer) {
      this.$router.push({ path: '/newCustomer', query: { customerId: customer.id } })
    },
    addNewCustomer () {
      this.$router.push('/newCustomer')
    },
    gotoDetailCustomer (customer) {
      this.$router.push('/customerDetail/' + customer.id)
    },
    async onLoad () {
      // console.log(this[`${this.active}CustomerList`])
      // console.log('运行load ===========', this[`${this.active}CustomerList`].length < this.pagination[this.active].total)
      // if(this[`${this.active}CustomerList`].length <= this.pagination[this.active].total) {
      //   }
      this.pagination[this.active].pageNo += 1
      this.listCustomer()
    },
  },
  beforeRouteEnter(to, from, next) {
    /**
     * 根据接口查看是否有权限查看客户管理
     * 无权限跳至403
     */
    getUserAuth()
      .then(res => {
        if(res.code === 200) {
          const customManage = res.data.find(r => r.name == "contact_manage");
          const canEnter = customManage.rules.find(rule => rule.name == "contact_view")?.checked
          if(canEnter) {
            next()
          } else {
            setTimeout(() => {
              next({path: '/403'})
            }, 200)
          }
        }
      })
  },
}
</script>
<style lang="less">
.unsettled,
.closed {
  margin-top: 32px;
  .search-input{
    margin-bottom: 20px;
  }
}
.customer-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 0 30px;
  margin-bottom: 20px;
  h3 {
    font-size: 32px;
    color: #051833;
  }
}
.tags {
  & > span + span {
    margin-left: 10px;
  }
}
.tab-hide {
  .van-tabs__wrap {
    display: none!important;
  }
}
.home{
  .custom-item{
    .customer-info{
      max-width: 450px;
      h3{
        word-break: break-all;
      }
    }
    
  }
  .empty{
    text-align: center;
    padding-top: 100px;
    img{
      width: 380px;
      height: 380px;
      object-fit: cover;
    }
    p{
      font-size: 28px;
      color: #8A98AC;
      line-height: 36px;
      margin-top: 36px;
    }
  }
  .track-btn{
    .track-btn-edit{
      height: 60px;
      line-height: 60px;
      font-size: 28px;
      color: #8A98AC;
      text-align: center;
      img{
        display: inline-block;
        width: 38px;
        height: 38px;
        vertical-align: sub;
      }
    }
  }
}
</style>
