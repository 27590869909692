import axios from 'axios'
import Cookies from 'js-cookie'
import {
  Toast
} from 'vant';
import router from '@/router'

const JsonBigInt = require('json-bigint')({
  storeAsString: true
})

axios.defaults.baseURL = process.env.VUE_APP_API
axios.defaults.withCredentials = true;

axios.defaults.headers = {
  'Content-Type': 'application/json;charset=utf8',
  // 'Authorization': 'Bearer ' + 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9hcGkuY3JtLnRlc3QubW9iLmNvbVwvYXBpXC9hdXRoXC9sb2dpbkJ5QUFBIiwiaWF0IjoxNjI1MTQyMDQ3LCJleHAiOjE2MjUyMjg0NDcsIm5iZiI6MTYyNTE0MjA0NywianRpIjoieDRienp2TkRiU1RFanBDNyIsInN1YiI6MzcsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjciLCJyb2xlIjoidXNlciJ9._of98sEFmH5iE7mB3fSi0G6BHxFyBpLzj8XZg-rWkJo'
}

// 请求拦截器
axios.interceptors.request.use(
  config => {
    const token = Cookies.get('token');
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    } else {
      Toast('登录过期或没有权限，请重新操作')
      setTimeout(() => {
        Cookies.remove('bo_token');
        Cookies.remove('token');
        router.push('/403')
      }, 200)
    }
    return config
  },
  error => Promise.reject(error)
)

axios.defaults.transformResponse = [
  function (response) {
    // 文件buffer
    if (response instanceof Blob) {
      return response
    }
    // 常规
    return JsonBigInt.parse(response)
  }
]

axios.interceptors.response.use(
  response => {
    console.log(response)
    if (response.data) {
      // 文件Blob
      if (response.data instanceof Blob) {
        return response
      } else if (response.data && response.data.code == 4005) {
        Toast('登录过期或没有权限，请重新操作');
        setTimeout(() => {
          Cookies.remove('bo_token');
          Cookies.remove('token');
          router.push('/403')
        }, 200)
      }
    }
    // success
    return response
  },
  error => {
    // 没有访问权限
    if (error.response && error.response.data && error.response.data.code === 403) {
      // setTimeout(() => reLogin(), 2000);
      // Vue.prototype.$message.error(error.response.data.message + "，并重新登录");
    }
    return Promise.reject(error.response)
  }
)

export function formateURLOnlyGet(link, json = {}) {
  let url = link
  var data = Object.entries(json)

  if (data.length) {
    url += url.indexOf('?') == -1 ? '?' : ''
    url += Object.entries(data)
      .map(item => {
        return item[1].join('=')
      })
      .join('&')
  }
  return url
}
/**
 * GET请求方法
 * @param {String} url 请求地址
 * @param {json} json 请求参数
 */
export function getData(url, json = {}) {
  return axios
    .get(formateURLOnlyGet(url, json))
    .then(res => res.data)
    .catch(error => Promise.reject(error.data))
}

export function postData(url, json = {}, config = {}) {
  return axios
    .post(url, json, config)
    .then(res => res.data)
    .catch(error => Promise.reject(error.data))
}

export function deleteData(url, json = {}) {
  return axios({
      url: formateURLOnlyGet(url, json),
      method: 'delete'
      // data: json
    })
    .then(res => res.data)
    .catch(error => Promise.reject(error.data))
}

export function deleteJSON(url, json = {}) {
  return axios({
      url: url,
      method: 'delete',
      data: json
    })
    .then(res => res.data)
    .catch(error => Promise.reject(error.data))
}

export function putData(url, json = {}) {
  return axios({
      url,
      method: 'put',
      data: json
    })
    .then(res => res.data)
    .catch(error => Promise.reject(error.data))
}

export function downloadFile(url, json = {}, config = {}) {
  return axios({
      url,
      method: 'post',
      data: json,
      withCredentials: true,
      responseType: 'blob',
      ...config
    })
    .then(res => res)
    .catch(error => error.response)
}
