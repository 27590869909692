import { getPersonRole } from '@/api/auth'

const actions = {
  getPersonRoleType ({ commit }, payload) {
    return new Promise((resolve, reject) => {
      getPersonRole(payload)
        .then(res => {
          if (res.code === 200) {
            console.log("res", res)
            commit('SET_PERSON_ROLE_TYPE', ((type) => {
              switch (type) {
                case 1:
                  return 'BUSINESS'
                case 2:
                  return 'SDK'
                default:
                  return null
              }
            })(res.data.personnel_type))
            resolve(res)
          }
        })
    })
  }
}

export default actions
