<template>
  <div class="tab-bar" >
    <van-tabbar
      v-model="active"
      @change="changeTab"
      active-color="#091c46"
      inactive-color="#8E8E8E">
      <van-tabbar-item
        v-for="(tab, tabIdx) in tabList"
        :key="tabIdx"
        :to="tab.href">
        <span :class="active==tabIdx ? 'isactive' : ''">{{tab.label}}</span>
        <div
          class="tab-icon"
          slot="icon">
          <img :src="active==tabIdx ? tab.activeImg : tab.img">
        </div>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
// import resource from "@/assets/tabbar/resource.png"
export default {
  props:{
    active: {
      type: Number,
      default: () => (0)
    },
  },
  data() {
    return {
      tabList: [
        {
          label: "客户管理",
          href: "/customer",
          img:require('@/assets/icon_nor_client.svg'),
          activeImg:require('@/assets/icon_sel_client.svg')
          // normal: resource,
          // active: activeResource
        },
        {
          label: "待办事项",
          href: "/agent",
          img:require('@/assets/icon_nor_item.svg'),
          activeImg:require('@/assets/icon_sel_item.svg')
          // normal: project,
          // active: activeProject
        }
      ]
    }
  },
  computed() {

  },
  methods: {
    changeTab(idx) {
      console.log(idx)
    }
  }
}
</script>
<style lang="less">
.tab-bar{
  .isactive{
    color: #029488;
  }
}
</style>