import router from './router'
import Cookies from 'js-cookie'
import {
  getAAAToken,
  getToken
} from '@/api/auth.js'
import store from '@/store'
import {
  Toast
} from 'vant';


const whiteRoutes = ['NoAuth', 'NotFound']

const hash = location.hash
const token = location.hash.match(new RegExp(`token=(\\S*)${hash.indexOf('&') === -1 ? '' : '&'}`))?. [1] || null;

Cookies.set('bo_token', token)
router.beforeEach(async (to, from, next) => {
  if (whiteRoutes.includes(to.name)) {
    next()
  }
  const bo_token = Cookies.get('bo_token')
  const token = Cookies.get('token')
  const hasToken = token && token !== 'null'
  //生产环境需注释
  // if(process.env.VUE_APP_LOCAL === 'true') {
  //   store.commit('SET_PERSON_ROLE_TYPE', 'BUSINESS')
  //   next()
  //   return
  // }
  try {
    if (bo_token && !hasToken) {
      const resAAA = await getAAAToken({
        token: bo_token
      })

      if (resAAA.status === 200) {
        const aaaToken = resAAA.content.token
        const res = await getToken({
          token: aaaToken
        })
        if (res.code === 200) {
          const {
            user,
            meta
          } = res.data
          const crmToken = meta.access_token;
          Cookies.set('token', crmToken);
          next({
            path: '/customer'
          })
          await store.dispatch('getPersonRoleType', {
            aaa_account_id: user.aaa_account_id
          })
        } else {
          throw (res.msg)
        }
      } else {
        // next({ path: '/403' })
        throw (resAAA.msg)
      }
    } else {
      next()
    }
  } catch (error) {
    Toast(error)
    Cookies.remove('bo_token');
    Cookies.remove('token');
    next({
      path: '/403'
    })
  }
})
