import {
  Button,
  Icon,
  NavBar,
  List,
  Cell,
  CellGroup,
  Tabbar,
  TabbarItem,
  PullRefresh,
  Tab,
  Tabs,
  Form,
  Field,
  Tag,
  Popup,
  Picker,
  Col,
  Row,
  Notify,
  Calendar,
  Loading,
  Search
} from 'vant';

export default {
  install(Vue) {
    Vue.use(Button)
      .use(Icon)
      .use(NavBar)
      .use(List)
      .use(Cell)
      .use(CellGroup)
      .use(Tabbar)
      .use(TabbarItem)
      .use(PullRefresh)
      .use(Tab)
      .use(Tabs)
      .use(Form)
      .use(Field)
      .use(Tag)
      .use(Popup)
      .use(Picker)
      .use(Col)
      .use(Row)
      .use(Notify)
      .use(Calendar)
      .use(Loading)
      .use(Search)
  }
}