import { getData, postData } from './_http'

export function getBusiness (json) {
  return getData('/api/outer/common/businessList', json)
}

export function getAreaList () {
  return getData('/api/outer/common/areaList')
}

export function createCustomerApi (json) {
  return postData('/api/outer/customer/add', json)
}

export function updateCustomerApi (json) {
  return postData('/api/outer/customer/update', json)
}

export function listCustomer (json) {
  return postData('/api/outer/customer/list', json)
}

export function getCustomerDetail (customerId) {
  return getData(`/api/outer/customer/detail/${customerId}`)
}

export function agentSchedule (json) {
  return getData('/api/outer/schedule/distributeList', json)
}
// 领取客户
export function receiveCustom (json) {
  return postData('/api/outer/clue/pick', json)
}
// 跟进团队列表
export function getFollowMember(json) {
  return getData('/api/outer/customer/personList', json)
}
export function getFollowList (json) {
  return postData('/api/outer/customer/follow/list', json)
}
// 发布跟进记录
export function addFollow (json) {
  return postData('/api/outer/customer/follow/add', json)
}