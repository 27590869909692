export const LEVEL_MAP = [
  {
    label: '高',
    value: 'A'
  },
  {
    label: '中',
    value: 'B'
  },
  {
    label: '低',
    value: 'C'
  }
]

export const STATUSTINY_MAP = [
  {
    label: '未成交',
    value: 1
  },
  {
    label: '已成交',
    value: 2
  }
]

export const SOURCE_MAP = [
  {
    label: '旧CRM系统导入',
    value: 1
  },
  {
    label: '线上询价',
    value: 2
  },
  {
    label: '搜索引擎',
    value: 3
  },
  {
    label: '个人资源',
    value: 4
  },
  {
    label: '转介绍',
    value: 5
  },
  {
    label: '官网留资',
    value: 6
  },
  {
    label: '市场数据',
    value: 7
  },
  {
    label: '客服数据',
    value: 8
  },
  {
    label: '其他',
    value: 9
  }
]

export const STAGE_MAP = {
  BUSINESS: [
    {
      label: '初步沟通',
      value: 1
    },
    {
      label: '已拜访',
      value: 2
    },
    {
      label: '需求沟通中',
      value: 3
    },
    {
      label: '需求确认',
      value: 4
    },
    {
      label: '测试',
      value: 5
    },
    {
      label: '报价',
      value: 6
    },
    {
      label: '已成交（合同中）',
      value: 7
    },
    {
      label: '回款中',
      value: 8
    },
    {
      label: '发票中',
      value: 9
    },
    {
      label: '完成',
      value: 10
    }
  ],
  SDK: [
    { value: "1001", label: "意向确认中" },
    { value: "1002", label: "方案评估中" },
    { value: "1003", label: "测试排期中" },
    { value: "1004", label: "合同完成" },
    { value: "1005", label: "产品对接中" },
    { value: "1006", label: "产品测试中" },
    { value: "1007", label: "上线灰度" },
    { value: "1008", label: "全量上线" },
    { value: "1009", label: "暂停跟进" }
  ]
}

export const INDUSTRY_MAP = [
  {
    label: '金融保险',
    value: 1
  },
  {
    label: '地产行业',
    value: 2
  },
  {
    label: '电子商务',
    value: 3
  },
  {
    label: '政府机关',
    value: 4
  },
  {
    label: '教育培训',
    value: 5
  },
  {
    label: '对外贸易',
    value: 6
  },
  {
    label: '医疗',
    value: 7
  },
  {
    label: '酒店旅游',
    value: 8
  },
  {
    label: '零售',
    value: 10
  },
  {
    label: '交通运输',
    value: 11
  },
  {
    label: '服务行业',
    value: 12
  },
  {
    label: '媒体娱乐',
    value: 13
  },
  {
    label: '系统工具',
    value: 14
  },
  {
    label: '游戏',
    value: 15
  }
]

export const GENDER_MAP = [
  {
    label: '女',
    value: 'f'
  },
  {
    label: '男',
    value: 'm'
  }
]

export const FOLLOW_TYPES = [
  {
    id: 1,
    text: "电话"
  }, {
    id: 2,
    text: "微信"
  }, {
    id: 3,
    text: "拜访"
  }, {
    id: 99,
    text: "其他"
  }
]

export const INDUSTRY_MAP2 = [
  {
    text: '金融保险',
    children: [
      {
        text: '',
      },
      {
        text: '股份制银行',
      }, {
        text: '城商行',
      }, {
        text: '农商行',
      }, {
        text: '消费金融',
      }, {
        text: '互金小贷',
      }, {
        text: '金科公司',
      }, {
        text: '保险',
      }, {
        text: '钱包支付',
      }, {
        text: '股票证券',
      }, {
        text: '数字货币',
      }, {
        text: '记账',
      }, {
        text: '股票证券',
      },
    ]
  }, {
    text: '社交社区',
    children: [
      {
        text: '',
      },
      {
        text: '社交工具',
      }, {
        text: '交友',
      }, {
        text: '婚恋',
      }, {
        text: '游戏陪玩',
      }, {
        text: '垂直社区',
      }
    ]
  }, {
    text: '影音娱乐',
    children: [
      {
        text: '',
      },
      {
        text: '长视频',
      }, {
        text: '短视频',
      }, {
        text: '直播',
      }, {
        text: '音乐',
      }, {
        text: '音频FM',
      }, {
        text: '摄影修图',
      }, {
        text: '阅读/小说',
      }, {
        text: '漫画',
      }, {
        text: '游戏',
      }, {
        text: '新闻资讯',
      }, {
        text: '垂直媒体',
      }
    ]
  }, {
    text: '电商零售',
    children: [
      {
        text: '',
      },
      {
        text: '综合电商',
      }, {
        text: '垂直电商',
      }, {
        text: '社区电商',
      }, {
        text: '二手电商',
      }, {
        text: '导购',
      }, {
        text: '电子商务',
      }
    ]
  }, {
    text: '生活服务',
    children: [
      {
        text: '',
      },
      {
        text: '分类信息',
      }, {
        text: '医疗健康',
      }, {
        text: '运动健身',
      }, {
        text: '快递物流',
      }, {
        text: '整形',
      }, {
        text: '美业',
      }, {
        text: '搬家服务',
      }, {
        text: '装修',
      }, {
        text: '手机营业厅',
      }, {
        text: '餐饮外卖',
      }, {
        text: '餐饮到店',
      }
    ]
  }, {
    text: '住宿出行',
    children: [
      {
        text: '',
      },
      {
        text: '综合旅游',
      }, {
        text: '网约车',
      }, {
        text: '共享单车',
      }, {
        text: '差旅出行',
      }, {
        text: '酒店民宿',
      }, {
        text: '地图导航',
      }, {
        text: '租车',
      }, {
        text: '火车票/飞机票',
      }, {
        text: '代驾',
      }, {
        text: '公交地铁',
      }
    ]
  }, {
    text: '教育培训',
    children: [
      {
        text: '',
      },
      {
        text: '知识付费',
      }, {
        text: 'K12教育',
      }, {
        text: '成人教育',
      }, {
        text: '母婴育儿',
      }
    ]
  }, {
    text: '汽车服务',
    children: [
      {
        text: '',
      },
      {
        text: '整车厂',
      }, {
        text: '综合汽车社区',
      }, {
        text: '新车/二手车',
      }, {
        text: '车后服务',
      }, {
        text: '汽车租赁',
      }
    ]
  }, {
    text: '职场办公',
    children: [
      {
        text: '',
      },
      {
        text: '求职招聘',
      }, {
        text: '办公软件',
      }, {
        text: '云计算',
      }
    ]
  }, {
    text: '快销',
    children: [
      {
        text: '',
      },
      {
        text: '饮料',
      }, {
        text: '食品',
      }, {
        text: '酒水',
      }, {
        text: '日化',
      }, {
        text: '其他',
      }
    ]
  }, {
    text: '零售',
    children: [
      {
        text: '',
      },
      {
        text: '服饰',
      }, {
        text: '百货',
      }, {
        text: '便利店',
      }, {
        text: '超市',
      }, {
        text: '其他',
      }
    ]
  }, {
    text: '地产行业',
    children: [
      {
        text: '',
      },
      {
        text: '买房租房',
      }, {
        text: '房产',
      }
    ]
  }, {
    text: '医疗',
    children: [
      {
        text: '',
      },
      {
        text: '医疗器械',
      }, {
        text: '医疗研发',
      }, {
        text: '医疗服务',
      }, {
        text: '医药商业',
      }
    ]
  }, {
    text: '政府机关',
    children: [
      {
        text: '',
      }
    ]
  }, {
    text: '对外贸易',
    children: [
      {
        text: '',
      }
    ]
  }, {
    text: '游戏',
    children: [
      {
        text: '',
      }
    ]
  }, {
    text: '其他',
    children: [
      {
        text: '',
      },
      {
        text: '工具',
      }, {
        text: '搜索引擎',
      }, {
        text: '浏览器',
      }, {
        text: '商务办公',
      }, {
        text: '地图导航',
      }, {
        text: '智能硬件',
      }
    ]
  }
]