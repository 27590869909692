import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import actions from './actions'
import mutations from './mutations'

Vue.use(Vuex)

const debug = process.env.NODE_ENV == 'dev';

export default new Vuex.Store({
  state,
  mutations,
  actions,
  strict: debug,
  middlewares: debug ? [] : []
})