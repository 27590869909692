<template>
  <div class="content">
    <ul class="operate-list">
      <li @click="gotoRecordList">打卡记录</li>&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;
      <li @click="gotoHelp">打卡说明</li>
    </ul>
  </div>
</template>
<script>
export default {
  methods: {
    gotoRecordList() {
      this.$router.push({name: 'RecordList'})
    },
    gotoHelp() {
      this.$router.push({name: 'ClockHelp'})
    }
  }
}
</script>

<style lang="less">
.operate-list{
  display: flex;
  justify-content: center;
  height: 60px;
  line-height: 60px;
  box-shadow:0px 0px 10px 0px rgba(47,51,51,0.1);
  li{
    margin-left: 20px;
    margin-right: 20px;
  }
}
</style>